define('ember-metrics/metrics-adapters/google-analytics', ['exports', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _canUseDom, _objectTransforms, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    isPresent,
    copy,
    assert,
    get,
    $,
    String: { capitalize }
  } = Ember;
  const { compact } = _objectTransforms.default;
  const assign = Ember.assign || Ember.merge;

  exports.default = _base.default.extend({
    toStringExtension() {
      return 'GoogleAnalytics';
    },

    init() {
      const config = copy(get(this, 'config'));
      const { id, sendHitTask, trace, require } = config;
      let { debug } = config;

      assert(`[ember-metrics] You must pass a valid \`id\` to the ${this.toString()} adapter`, id);

      delete config.id;
      delete config.require;

      if (debug) {
        delete config.debug;
      }
      if (sendHitTask) {
        delete config.sendHitTask;
      }
      if (trace) {
        delete config.trace;
      }

      const hasOptions = isPresent(Object.keys(config));

      if (_canUseDom.default) {

        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
          }, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);
        })(window, document, 'script', `https://www.google-analytics.com/analytics${debug ? '_debug' : ''}.js`, 'ga');
        /* eslint-enable */

        if (trace === true) {
          window.ga_debug = { trace: true };
        }

        if (hasOptions) {
          window.ga('create', id, config);
        } else {
          window.ga('create', id, 'auto');
        }

        if (require) {
          require.forEach(plugin => {
            window.ga('require', plugin);
          });
        }

        if (sendHitTask === false) {
          window.ga('set', 'sendHitTask', null);
        }
      }
    },

    identify(options = {}) {
      const compactedOptions = compact(options);
      const { distinctId } = compactedOptions;

      if (_canUseDom.default) {
        window.ga('set', 'userId', distinctId);
      }
    },

    trackEvent(options = {}) {
      const compactedOptions = compact(options);
      const sendEvent = { hitType: 'event' };
      const eventKeys = ['category', 'action', 'label', 'value'];
      let gaEvent = {};

      if (compactedOptions.nonInteraction) {
        gaEvent.nonInteraction = compactedOptions.nonInteraction;
        delete compactedOptions.nonInteraction;
      }

      for (let key in compactedOptions) {
        if (eventKeys.includes(key)) {
          const capitalizedKey = capitalize(key);
          gaEvent[`event${capitalizedKey}`] = compactedOptions[key];
        } else {
          gaEvent[key] = compactedOptions[key];
        }
      }

      const event = assign(sendEvent, gaEvent);
      if (_canUseDom.default) {
        window.ga('send', event);
      }

      return event;
    },

    trackPage(options = {}) {
      const compactedOptions = compact(options);
      const sendEvent = { hitType: 'pageview' };

      const event = assign(sendEvent, compactedOptions);
      for (let key in compactedOptions) {
        if (compactedOptions.hasOwnProperty(key)) {
          window.ga('set', key, compactedOptions[key]);
        }
      }
      if (_canUseDom.default) {
        window.ga('send', sendEvent);
      }

      return event;
    },

    willDestroy() {
      if (_canUseDom.default) {
        $('script[src*="google-analytics"]').remove();
        delete window.ga;
      }
    }
  });
});